import {
  vObject,
  vOptionalDef,
  vOutput,
  vPicklist,
  vString,
} from '../validation'

const verifyShipperTokenTypes = ['shipper', 'vendor'] as const

export type VerifyShipperTokenType = (typeof verifyShipperTokenTypes)[number]

export const verifyTokenSchema = vObject({
  token: vString(),
  type: vOptionalDef(vPicklist(verifyShipperTokenTypes), 'shipper'),
})
export type VerifyTokenDto = vOutput<typeof verifyTokenSchema>

export interface VerifyTokenResponse {
  success: boolean
}
